import HeroBanner from '@components/routes/Blog/HeroBanner';
import routeMap from '@utils/routeMap';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import PortableText from 'react-portable-text';

import SEO from '@theme/SEO';

import Box from '@components/atoms/Box';
import GridChild from '@components/atoms/GridChild';
import GridParent from '@components/atoms/GridParent';
import LinkWithArrow from '@components/atoms/LinkWithArrow';

import Layout from '@components/templates/Layout';
import ImageRenderer from '@components/templates/SanityRenderer/ImageRenderer';
import {
    AuthorReference,
    SanityH1,
    SanityH2,
    SanityH3,
    SanityNormal,
} from '@components/templates/SanityRenderer/SanityTypographyMap';

const BlogPage = ({ pageContext, data }: any) => {
    const { t } = useTranslation();

    return (
        <Layout pageContext={pageContext}>
            <SEO />
            <HeroBanner bg={pageContext.image} title={pageContext.title} slug={pageContext.slug} />
            <Box as="section" mt={100} mb={140}>
                <GridParent as="article">
                    <GridChild gridColumn={{ _: 'span 12', laptopS: '4/ span 6' }}>
                        <PortableText
                            /* eslint-disable-next-line no-underscore-dangle */
                            content={data.post._rawBody}
                            serializers={{
                                mainImage: ImageRenderer,
                                h1: SanityH1,
                                h2: SanityH2,
                                h3: SanityH3,
                                normal: SanityNormal,
                                authorReference: AuthorReference,
                            }}
                        />
                    </GridChild>
                    <GridChild gridColumn={{ _: 'span 12', laptopS: '4/ span 6' }}>
                        <Box textAlign="center">
                            <LinkWithArrow
                                to={routeMap.info.blog}
                                label={t('Go back to blogs')}
                                isGoBackBtn
                            />
                        </Box>
                    </GridChild>
                </GridParent>
            </Box>
        </Layout>
    );
};

export default BlogPage;

export const query = graphql`
    query BlogPostTemplateQuery($slug: String!) {
        post: sanityPost(slug: { current: { eq: $slug } }) {
            id
            publishedAt
            categories {
                _id
                title
            }
            mainImage {
                asset {
                    gatsbyImageData
                }
                alt
            }
            title
            slug {
                current
            }
            _rawExcerpt(resolveReferences: { maxDepth: 5 })
            _rawBody(resolveReferences: { maxDepth: 10 })
            authors {
                author {
                    image {
                        alt
                        asset {
                            url
                        }
                        caption
                    }
                }
            }
        }
    }
`;
